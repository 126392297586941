import { Dispatch } from 'redux'
import {
  GetTimesheet as GetTimesheetAction,
  GetTimesheetErrors,
  TimesheetFetching,
  GetProperty,
  GetPropertyErrors,
  GetTimesheetShifts as GetShiftsAction,
  GetShiftsErrors,
  ShiftsFetching,
  GetApplications as GetApplicationsAction,
  GetApplicationsErrors,
  ApplicationsFetching,
  GetPunchCardsForTimesheet as GetPunchCardsAction,
  GetPunchCardsErrors,
  PunchCardsFetching,
  AddTimesheetPunchCard as AddPunchCardAction,
  AddTimesheetPunchCardErrors,
  DeleteTimesheetPunchCard as DeletePunchCardAction,
  DeleteTimesheetPunchCardErrors,
  UpdateTimesheetPunchCard as UpdatePunchCardAction,
  UpdateTimesheetPunchCardErrors,
  GigDaysFetching,
  GetGigDays as GetGigDaysAction,
  GetGigDaysErrors,
  FullTimesheetFetching,
  AddApplication,
  AddApplicationErrors,
  UpdateTimesheet as UpdateTimesheetAction,
  GetBonusesForTimesheet as GetBonusesAction,
  GetBonusesErrors,
  BonusesFetching,
  AddBonus as AddBonusAction,
  AddBonusErrors,
  UpdateBonus as UpdateBonusAction,
  DeleteBonus as DeleteBonusAction,
  UpdateBonusErrors,
  ClearTimesheet,
} from './actions'
import { API } from 'network'
import { IGetShiftsParams, IShift, SHIFT_TYPE } from 'models/Shift'
import { IGetApplicationsParams, IPostApplicationParams } from 'models/Application'
import {
  IGetPunchCardsParams,
  IPutPunchCardParams,
  IPostPunchCardParams,
  PUNCH_CARD_STATUS,
} from 'models/PunchCard'
import { NotificationManager } from 'helpers/NotificationsManager'
import { IGetGigDaysParams, IGigDay, IGigDayExtended } from 'models/GigDay'
import { IPayload } from 'models/Global'
import { IGetTimesheetsParams, IPutTimesheetParams, TIMESHEET_STATUS } from 'models/Timesheet'
import { handleErrorNotification } from 'services/ErrorHandlingService'
import {
  IDeleteBonusParams,
  IGetBonusesParams,
  IPostBonusParams,
  IPutBonusParams,
} from 'models/Bonus'
import { renderRevokeStrikeModal } from '../Upshifter/utils'
import { CODE_481_ACTIONS, POST_APPLICATION_481_ACTIONS } from 'network/config/error'
import { AppState } from 'store/store'

// const customConfig = {
//   type: NOTIFICATION_TYPE.WARNING,
//   container: NOTIFICATION_CONTAINER.CENTER,
//   width: 550,
//   dismiss: {
//     duration: 0,
//     onScreen: true,
//   },
// }

export type GetTimesheetActions =
  | ReturnType<typeof GetTimesheetAction>
  | ReturnType<typeof GetTimesheetErrors>
  | ReturnType<typeof TimesheetFetching>

export type UpdateTimesheetActions =
  | ReturnType<typeof UpdateTimesheetAction>
  | ReturnType<typeof GetTimesheetErrors>
  | ReturnType<typeof TimesheetFetching>

export type TimesheetActions = GetTimesheetActions | UpdateTimesheetActions

export type GetShiftsActions =
  | ReturnType<typeof GetShiftsAction>
  | ReturnType<typeof GetShiftsErrors>
  | ReturnType<typeof ShiftsFetching>

export type ApplicationsActions =
  | ReturnType<typeof GetApplicationsAction>
  | ReturnType<typeof GetApplicationsErrors>
  | ReturnType<typeof ApplicationsFetching>
  | ReturnType<typeof AddApplication>
  | ReturnType<typeof AddApplicationErrors>

export type PunchCardsActions =
  | ReturnType<typeof GetPunchCardsAction>
  | ReturnType<typeof GetPunchCardsErrors>
  | ReturnType<typeof PunchCardsFetching>
  | ReturnType<typeof AddPunchCardAction>
  | ReturnType<typeof AddTimesheetPunchCardErrors>
  | ReturnType<typeof UpdatePunchCardAction>
  | ReturnType<typeof UpdateTimesheetPunchCardErrors>
  | ReturnType<typeof DeletePunchCardAction>
  | ReturnType<typeof DeleteTimesheetPunchCardErrors>

export type BonusesActions =
  | ReturnType<typeof GetBonusesAction>
  | ReturnType<typeof GetBonusesErrors>
  | ReturnType<typeof BonusesFetching>
  | ReturnType<typeof AddBonusAction>
  | ReturnType<typeof AddBonusErrors>
  | ReturnType<typeof UpdateBonusAction>
  | ReturnType<typeof UpdateBonusErrors>
  | ReturnType<typeof DeleteBonusAction>

export type GetGigDaysActions =
  | ReturnType<typeof GetGigDaysAction>
  | ReturnType<typeof GetGigDaysErrors>
  | ReturnType<typeof GigDaysFetching>

export const getTimesheet =
  (timesheetId: number, params?: IGetTimesheetsParams) =>
  async (dispatch: Dispatch<GetTimesheetActions>) => {
    let response = null

    try {
      response = await API.Timesheets.getTimesheet(timesheetId, params)
      dispatch(GetTimesheetAction(response))
    } catch (error) {
      dispatch(
        GetTimesheetErrors({
          code: error.code,
          message: error.message,
        })
      )
      response = error
    }
    return response
  }

export const updateTimesheet =
  (timesheetId: number, params: IPutTimesheetParams) =>
  async (dispatch: Dispatch<UpdateTimesheetActions>) => {
    let response = null

    try {
      response = await API.Timesheets.putTimesheet(timesheetId, params)
      dispatch(UpdateTimesheetAction(response))
      NotificationManager.success('Timesheet updated successfully.')
    } catch (error) {
      dispatch(
        GetTimesheetErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      response = error
    }

    return response
  }

export const getProperty = (propertyId: number) => async (dispatch: any) => {
  let response = null

  try {
    response = await API.Properties.getProperty(propertyId)
    dispatch(GetProperty(response))
  } catch (error) {
    dispatch(
      GetPropertyErrors({
        code: error.code,
        message: error.message,
      })
    )
    handleErrorNotification(error)
    response = error
  }
  return response
}

export const getShifts =
  (params?: IGetShiftsParams) => async (dispatch: Dispatch<GetShiftsActions>) => {
    let response = null

    try {
      response = await API.Shifts.getShifts(params)
      dispatch(GetShiftsAction(response))
    } catch (error) {
      dispatch(
        GetShiftsErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      response = error
    }
    return response
  }

export const getApplications =
  (params?: IGetApplicationsParams) => async (dispatch: Dispatch<ApplicationsActions>) => {
    let response = null

    try {
      response = await API.Applications.getApplications(params)
      dispatch(GetApplicationsAction(response))
    } catch (error) {
      dispatch(
        GetApplicationsErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      response = error
    }
    return response
  }

export const addApplication =
  (params?: IPostApplicationParams) => async (dispatch: Dispatch<ApplicationsActions>) => {
    try {
      const response = await API.Applications.postApplication(params)
      dispatch(AddApplication(response))
      NotificationManager.success('Upshifter added successfully')
      return response
    } catch (error) {
      dispatch(AddApplicationErrors({ code: error.code, message: error.message }))
      // If the adding an application results in any special error action that needs to be handled with a bypass parameter,
      // Then don't show an error notification, because it is handled by a pop up confirmation modal with bypass param on component level
      if (POST_APPLICATION_481_ACTIONS.includes(error.action)) throw error
      handleErrorNotification(error)
      throw Error(error)
    }
  }

export const getBonuses =
  (params?: IGetBonusesParams) => async (dispatch: Dispatch<BonusesActions>) => {
    try {
      const response = await API.Bonuses.getBonuses(params)
      dispatch(GetBonusesAction(response))
      return response
    } catch (error) {
      dispatch(
        GetBonusesErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      throw error
    }
  }

export const addBonus =
  (params: IPostBonusParams) =>
  async (dispatch: Dispatch<BonusesActions | GetTimesheetActions>) => {
    try {
      const response = await API.Bonuses.postBonus({
        ...params,
        include: ['application', 'created_by'],
      })
      dispatch(AddBonusAction(response))
      NotificationManager.success('Bonus added successfully.')
      const timesheetId = params.timesheet_id ? params.timesheet_id : response.data.timesheet_id
      const timesheet = await API.Timesheets.getTimesheet(timesheetId, {
        include: ['attachments_count', 'business', 'notes', 'business.instruction'],
      })
      dispatch(GetTimesheetAction(timesheet))
      return response
    } catch (error) {
      dispatch(
        AddBonusErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      throw error
    }
  }

export const deleteBonus =
  (params: IDeleteBonusParams) =>
  async (dispatch: Dispatch<BonusesActions | GetTimesheetActions>) => {
    try {
      const response = await API.Bonuses.deleteBonus(params.id)
      dispatch(DeleteBonusAction(params))
      NotificationManager.success('Bonus deleted successfully.')
      const timesheet = await API.Timesheets.getTimesheet(params.timesheet_id, {
        include: ['attachments_count', 'business', 'notes', 'business.instruction'],
      })
      dispatch(GetTimesheetAction(timesheet))
      return response
    } catch (error) {
      dispatch(
        UpdateBonusErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)

      throw error
    }
  }

export const updateBonus =
  (id: number, params: IPutBonusParams) =>
  async (dispatch: Dispatch<BonusesActions | GetTimesheetActions>) => {
    try {
      const response = await API.Bonuses.putBonus(id, {
        ...params,
        include: ['application', 'created_by'],
      })
      dispatch(UpdateBonusAction(response))
      NotificationManager.success('Bonus updated successfully.')
      const timesheet = await API.Timesheets.getTimesheet(response.data.timesheet_id, {
        include: ['attachments_count', 'business', 'notes', 'business.instruction'],
      })
      dispatch(GetTimesheetAction(timesheet))
      return response
    } catch (error) {
      dispatch(
        UpdateBonusErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)

      throw error
    }
  }

export const getPunchCards =
  (params?: IGetPunchCardsParams) => async (dispatch: Dispatch<PunchCardsActions>) => {
    let response = null

    try {
      response = await API.PunchCards.getPunchCards(params)
      dispatch(GetPunchCardsAction(response))
    } catch (error) {
      dispatch(
        GetPunchCardsErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      response = error
    }
    return response
  }

export const addPunchCard =
  (params: IPostPunchCardParams) =>
  async (dispatch: Dispatch<PunchCardsActions>, getState: () => AppState) => {
    // dispatch(PunchCardsFetching(true));
    try {
      const response = await API.PunchCards.postPunchCard(params)
      const timesheetId = getState().timesheet.data.timesheet.id
      // in case of adding a punch card in a processed timesheet, the punch card is added to a new timesheet
      // so we don't want to update the store with the new punch card in this scenario
      if (timesheetId === response.data.timesheet_id) {
        dispatch(AddPunchCardAction(response))
      }
      NotificationManager.success('Punch Card created successfully.')

      const punchCard = await API.PunchCards.getPunchCard(response.data.id, {
        include: ['no_call_no_show_strike'],
      })
      if (
        punchCard.data.no_call_no_show_strike &&
        punchCard.data.gig_id === punchCard.data.no_call_no_show_strike.gig.id &&
        punchCard.data.status !== PUNCH_CARD_STATUS.NO_TERM_ACTION
      ) {
        renderRevokeStrikeModal({
          strikeId: punchCard.data.no_call_no_show_strike.id,
          upshifterName: punchCard.data.application_full_name,
        })
      }
      // TODO: Feature should be disabled
      // NotificationManager.warning(
      //   'Please add the Timesheet Documentation. Click on the notification to dismiss.',
      //   'Reminder',
      //   customConfig
      // )
      return response
    } catch (error) {
      dispatch(AddTimesheetPunchCardErrors({ code: error.code, message: error.message }))
      if (
        error.action === CODE_481_ACTIONS.OVERLAPPING_PUNCH_CARD ||
        error.action === CODE_481_ACTIONS.UPSHIFTER_NOT_ACCEPTED_FOR_GIG_DAY
      ) {
        throw error
      }
      handleErrorNotification(error)
      return null
    }
  }

export const updatePunchCard =
  (id: number, params: IPutPunchCardParams) => async (dispatch: Dispatch<PunchCardsActions>) => {
    // dispatch(PunchCardsFetching(true));
    try {
      const response = await API.PunchCards.putPunchCard(id, params)
      dispatch(UpdatePunchCardAction(response))
      NotificationManager.success('Punch Card updated successfully.')
      // TODO: Feature should be disabled
      // NotificationManager.warning(
      //   'Please add the Timesheet Documentation. Click on the notification to dismiss.',
      //   'Reminder',
      //   customConfig
      // )
      return response
    } catch (error) {
      dispatch(
        UpdateTimesheetPunchCardErrors({
          code: error.code,
          message: error.message,
        })
      )
      if (
        error.action === CODE_481_ACTIONS.OVERLAPPING_PUNCH_CARD ||
        error.action === CODE_481_ACTIONS.UPSHIFTER_NOT_ACCEPTED_FOR_GIG_DAY
      ) {
        throw error
      }
      handleErrorNotification(error)
      return null
    }
  }

export const deletePunchCard = (id: number) => async (dispatch: Dispatch<PunchCardsActions>) => {
  // dispatch(PunchCardsFetching(true));
  try {
    const response = await API.PunchCards.deletePunchCard(id)
    dispatch(DeletePunchCardAction(id))
    NotificationManager.success('Punch Card deleted successfully.')
    return response
  } catch (error) {
    dispatch(
      DeleteTimesheetPunchCardErrors({
        code: error.code,
        message: error.message,
      })
    )
    handleErrorNotification(error)
    throw new Error(error)
  }
}

export const getGigDays =
  (params: IGetGigDaysParams) => async (dispatch: Dispatch<GetGigDaysActions>) => {
    try {
      const { gig_id } = params
      const response = await API.GigDays.getGigDays(params)
      const r = {
        ...response,
        data: response.data.map((shift: IGigDay) => ({
          ...shift,
          shift_id: gig_id,
        })),
      } as IPayload<IGigDayExtended[]>

      dispatch(GetGigDaysAction(r))
    } catch (error) {
      dispatch(
        GetGigDaysErrors({
          code: error.code,
          message: error.message,
        })
      )
    }
  }

export const getFullTimesheet =
  (timesheetId: number, params?: IGetTimesheetsParams) => async (dispatch: any) => {
    dispatch(FullTimesheetFetching(true))

    const timesheet = await dispatch(getTimesheet(timesheetId, params))

    // If the getTimesheet thunk returns error from the API, it is stored in the reducer and we display it in the component.
    if (timesheet.data) {
      const { property_id: propertyId } = timesheet.data
      dispatch(getProperty(propertyId))

      const shiftFilter = {
        timesheet_id: timesheetId,
        paginate: false,
        ...(Number(timesheet.data.status) === TIMESHEET_STATUS.PENDING && {
          include: ['timesheets'] as ['timesheets'],
        }),
      }

      const shifts = await dispatch(getShifts(shiftFilter))
      const shiftIds = shifts.data.map((shift: IShift) => shift.id)

      const applicationsAndPunchCardsFilter = {
        timesheet_id: timesheetId,
        gig_ids: shiftIds,
        paginate: false,
      }

      await dispatch(
        getApplications({
          ...applicationsAndPunchCardsFilter,
          show_group_applications: true,
          include: {
            blocks_count: { business_id: timesheet.data.business_id },
            block_properties_count: { property_id: propertyId },
            0: 'all_not_declined_punch_cards_count',
            1: 'all_active_punch_cards_count',
          },
        })
      )
      await dispatch(getPunchCards(applicationsAndPunchCardsFilter))

      await dispatch(
        getBonuses({
          timesheet_id: timesheetId,
          paginate: false,
          include: ['created_by', 'application'],
        })
      )

      await Promise.all(
        shifts.data.map(async ({ id, type }: IShift) => {
          if (Number(type) === SHIFT_TYPE.MULTIDAY) {
            const params = {
              gig_id: id,
              timesheet_id: timesheetId,
            }

            await dispatch(getGigDays(params))
          }
        })
      )
    }

    dispatch(FullTimesheetFetching(false))
  }

export const updateFullTimesheet =
  (timesheetId: number, params: IPutTimesheetParams = {}) =>
  async (dispatch: any) => {
    dispatch(FullTimesheetFetching(true))

    const timesheet = await dispatch(updateTimesheet(timesheetId, params))
    const { property_id: propertyId } = timesheet.data
    dispatch(getProperty(propertyId))

    const shiftFilter = {
      timesheet_id: timesheetId,
      paginate: false,
    }

    const shifts = await dispatch(getShifts(shiftFilter))
    const shiftIds = shifts.data.map((shift: IShift) => shift.id)

    const applicationsAndPunchCardsFilter = {
      timesheet_id: timesheetId,
      gig_ids: shiftIds,
      paginate: false,
    }

    await dispatch(getApplications(applicationsAndPunchCardsFilter))
    await dispatch(getPunchCards(applicationsAndPunchCardsFilter))
    await dispatch(
      getBonuses({
        timesheet_id: timesheetId,
        paginate: false,
        include: ['created_by', 'application'],
      })
    )

    await Promise.all(
      shifts.data.map(async ({ id, type }: IShift) => {
        if (Number(type) === SHIFT_TYPE.MULTIDAY) {
          const params = {
            gig_id: id,
            timesheet_id: timesheetId,
          }

          await dispatch(getGigDays(params))
        }
      })
    )

    dispatch(FullTimesheetFetching(false))
  }

export const clearTimesheet = () => (dispatch: any) => {
  dispatch(ClearTimesheet())
}
