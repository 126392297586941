import React, { useState } from 'react'
import { useModal } from './ModalContext'
import { Button, Modal } from '@pinchenterprisesnpm/friday-ui'
import { FooterWrapper, BtnWrapper } from './style'

const Header = Modal.Header
const Content = Modal.Content

export const GenericModalV2 = () => {
  const { isModalVisible, modalConfig, hideModal } = useModal()

  const [isConfirmLoading, setConfirmLoading] = useState(false)

  if (!isModalVisible || !modalConfig) return null

  const { message, onConfirm, onCancel, isLoading, title } = modalConfig

  const handleConfirm = async () => {
    if (onConfirm) {
      try {
        setConfirmLoading(true)
        await onConfirm()
      } catch (error) {
        // TODO: Hanlde error
        // eslint-disable-next-line no-console
        console.error('Error during confirm:', error)
      } finally {
        setConfirmLoading(false)
        hideModal()
      }
    }
  }

  return (
    <Modal
      isOpen={isModalVisible}
      closeModal={hideModal}
      height='auto'
      animation
      animationType='topSlideIn'
      width={modalConfig.width || '400px'}
    >
      <Header title={title} closeModal={hideModal} />
      <Content>
        <p>{message}</p>
      </Content>
      <FooterWrapper>
        <Button
          isFilledOutlined
          type='dark'
          onClick={() => {
            // TODO: Not sure if this is the best approach
            if (onCancel) {
              onCancel()
            }
            hideModal()
          }}
          isDisabled={isLoading || isConfirmLoading}
        >
          No
        </Button>
        <BtnWrapper>
          <Button
            type='primary'
            onClick={async () => {
              handleConfirm()
            }}
            isLoading={isLoading || isConfirmLoading}
            isDisabled={isLoading || isConfirmLoading}
          >
            Yes
          </Button>
        </BtnWrapper>
      </FooterWrapper>
    </Modal>
  )
}
